import React from 'react'
import { Link } from 'gatsby'

import {
  StripeCheckoutRedirectContainer,
  ShapeContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectHeader,
  StripeCheckoutImage,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
} from 'components/StripeCheckout'
import LottieShape from 'components/LottieShape'
import AbstractPaymentAnimation from 'components/Animations/AbstractPaymentAnimation'

import cancel from 'images/png/cancel.png'

const Cancel = () => {
  return (
    <StripeCheckoutRedirectContainer>
      <ShapeContainer>
        <LottieShape size={500} animationData={AbstractPaymentAnimation} />
      </ShapeContainer>
      <StripeCheckoutContentContainer>
        <StripeCheckoutRedirectHeader>
          <StripeCheckoutImage src={cancel} alt="cancel" />
          Cancelar el checkout
        </StripeCheckoutRedirectHeader>
        <StripeCheckoutRedirectDescription>
          No se le cobrará porque usted canceló la transacción antes de
          finalizar el proceso de pago
        </StripeCheckoutRedirectDescription>
        <Link
          css={`
            width: 300px;
          `}
          to="/profile"
        >
          <StripeCheckoutRedirectButton>
            VOLVER A LA CUENTA
          </StripeCheckoutRedirectButton>
        </Link>
      </StripeCheckoutContentContainer>
    </StripeCheckoutRedirectContainer>
  )
}

export default Cancel
